import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../UserContext';
import { CurrencyDollarIcon, ChartBarIcon } from '@heroicons/react/24/solid';

const Holdings = () => {
  const { token } = useUser();
  const navigate = useNavigate();
  const [holdingsData, setHoldingsData] = useState({
    totalCoins: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchHoldingsData = async () => {
      if (!token) {
        setIsLoading(false);
        return;
      }
      try {
        const response = await axios.get('https://api.mymeme.exchange/holdings', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('Data: ', response.data)
        setHoldingsData(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching holdings data:', err);
        setError('Failed to load holdings data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchHoldingsData();
  }, [token]);

  if (isLoading) return <div className="text-center text-[#F7931A] text-xl animate-pulse">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  const { totalCoins, totalMarketValue, coinHoldings } = holdingsData;

  const handleEmptyHoldingsClick = () => {
    navigate('/explore');
  };

  return (
    <div className="space-y-6 p-2 rounded-xl">
      {/* Holdings Summary */}
      <div className="bg-white rounded-lg shadow-lg p-4">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-2xl font-bold text-[#F7931A]">Portfolio</h2>
          <CurrencyDollarIcon className="h-6 w-6 text-[#F7931A]" />
        </div>
        <div className="space-y-2">
          <div>
            <p className="text-sm text-[#F7931A]">MEMEs:</p>
            <p className="text-2xl font-bold text-[#F7931A]">{totalCoins}</p>
          </div>
          <div>
            <p className="text-sm text-[#F7931A]">Market Value:</p>
            <p className="text-2xl font-bold text-[#F7931A]">-</p>
            {/* <p className="text-2xl font-bold text-[#F7931A]">{totalMarketValue}</p> */}
          </div>
        </div>
      </div>

      {/* Coin Holdings */}
      <div>
        <h2 className="text-2xl font-bold text-[#F7931A] mb-3">MEMEs</h2>
        {coinHoldings && coinHoldings.length > 0 ? (
          <div className="space-y-3">
            {coinHoldings.map((holding) => (
              <Link
                key={holding.coinId}
                to={`/coin/${holding.coinId}`}
                className="bg-white rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 flex"
              >
                <div className="w-20 h-20 flex-shrink-0">
                  <img
                    src={holding.coin.iconUrl}
                    alt={holding.coin.name}
                    className="w-full h-full object-cover rounded-l-lg"
                  />
                </div>
                <div className="flex-grow p-4 flex items-center">
                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <div>
                        <span className="font-bold text-[#F7931A] text-lg mr-2">{holding.coin.name} </span>
                        <p className="text-sm text-orange-500">
                        {holding.coin.supply}
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="text-sm font-semibold text-[#F7931A]">{holding.coinAmount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <div
            className="bg-white rounded-lg shadow-lg p-4 text-center cursor-pointer hover:bg-orange-100 transition-colors duration-200"
            onClick={handleEmptyHoldingsClick}
          >
            <p className="text-[#F7931A]">You don't have any coin holdings yet. Click here to start exploring and investing!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Holdings;
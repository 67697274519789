import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../UserContext';

const Character = () => {
  const [tabValue, setTabValue] = useState(0);
  const [character, setCharacter] = useState(null);
  const [posts, setPosts] = useState([]);
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { token } = useUser();

  useEffect(() => {
    const fetchCharacter = async () => {
      try {
        const response = await axios.get(`https://api.mymeme.exchange/characters/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (!response.data) {
          throw new Error('Failed to fetch character data');
        }
        const { character, coins, posts } = response.data;
        setCharacter(character);
        setCoins(coins);
        setPosts(posts);
      } catch (error) {
        console.error('Error fetching character:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCharacter();
  }, [id]);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!character) {
    return <div>Character not found</div>;
  }

  return (
    <div className="character-page">
      {/* First segment: Character info */}
      {/* First card: Character image, name, and description */}
      <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 mb-4">
        <div className="w-full h-64">
          <img
            src={character.imageUrl}
            alt={character.name}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-4">
          <h2 className="font-bold text-[#F7931A] text-xl mb-2">{character.name}</h2>
          <p className="text-sm text-orange-500">{character.description}</p>
        </div>
      </div>

      {/* Second card: Summary of details
      <div className="bg-white rounded-lg shadow-lg p-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-[#F7931A]">Supply</p>
            <p className="text-lg font-bold text-[#F7931A]">-</p>
          </div>
          <div>
            <p className="text-sm text-[#F7931A]">Progress, %</p>
            <p className="text-lg font-bold text-[#F7931A]">-</p>
          </div>
          <div>
            <p className="text-sm text-[#F7931A]">Posts</p>
            <p className="text-lg font-bold text-[#F7931A]">{posts.length}</p>
          </div>
          <div>
            <p className="text-sm text-[#F7931A]">Coins</p>
            <p className="text-lg font-bold text-[#F7931A]">{coins.length}</p>
          </div>
        </div>
      </div> */}


      <div className="flex justify-center mb-4">
        <button
          onClick={() => handleTabChange(0)}
          className={`px-4 py-2 font-bold ${tabValue === 0 ? 'text-white bg-[#F7931A]' : 'text-[#F7931A] bg-white'} rounded-l-lg transition-colors duration-200`}
        >
          Post ({posts.length})
        </button>
        <button
          onClick={() => handleTabChange(1)}
          className={`px-4 py-2 font-bold ${tabValue === 1 ? 'text-white bg-[#F7931A]' : 'text-[#F7931A] bg-white'} rounded-r-lg transition-colors duration-200`}
        >
          Coin ({coins.length})
        </button>
      </div>

      {tabValue === 0 && posts.map(post => (
        <Link key={post.id} className="bg-white rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 flex flex-col cursor-pointer mb-4" to={`/post/${post.id}`}>
          <div className="w-full h-40">
            <img src={post.imageUrl} alt={post.character.name} className="w-full h-full object-cover" />
          </div>
          <div className="p-4">
            <h3 className="font-bold text-[#F7931A] text-lg">{post.character.name}</h3>
            <p className="text-sm text-orange-500">{post.text}</p>
            <p className="text-xs text-orange-400 mt-1">{new Date(post.createdAt).toLocaleString()}</p>
          </div>
        </Link>
      ))}

      {tabValue === 1 && coins.map(coin => (
        <Link
          key={coin.id}
          to={`/coin/${coin.id}`}
          className="bg-white mb-4 rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 flex"
        >
          <div className="w-20 h-20 flex-shrink-0">
            <img
              src={coin.iconUrl}
              alt={coin.name}
              className="w-full h-full object-cover rounded-l-lg"
            />
          </div>
          <div className="flex-grow p-4 flex items-center">
            <div className="w-full">
              <div className="flex items-center justify-between">
                <div>
                  <span className="text-[#F7931A] text-base mr-2">{coin.name} </span>
                  <p className="text-sm text-orange-500 mt-1">
                    {coin.userId ? `Created by: @${coin.user.telegramUsername}` : coin.characterId ? `By ${coin.character.name}` : ''}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-sm font-semibold text-[#F7931A]">
                    {coin.supply > 0
                      ? ((coin.supply / 1_000_000_000) * 100).toFixed(2)
                      : '0.00'}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ))}


    </div>
  );
};

export default Character;
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';

const CreateCoin = () => {
    const [formData, setFormData] = useState({
        name: '',
        symbol: '',
        description: '',
        initialPurchase: 0
    });
    const [icon, setIcon] = useState(null);
    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showMoreInputs, setShowMoreInputs] = useState(false);  // Add this line
    const navigate = useNavigate();
    const { userInfo } = useUser();

    const InfoIcon = ({ tooltip }) => (
        <span className="ml-1 text-gray-500 cursor-help" title={tooltip}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        </span>
    );    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        setIcon(e.target.files[0]);
    };

    const handleImageFileChange = (e) => {
        setImage(e.target.files[0]);
    };    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        const confirmed = window.confirm('Creating a MEME will cause immense pain or pleasure. Do you want to continue?');
        if (!confirmed) {
            return;
        }

        setIsLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('symbol', formData.symbol);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('initialPurchase', formData.initialPurchase);
        if (icon) {
            formDataToSend.append('icon', icon);
        }
        if (image) {
            formDataToSend.append('image', image);
        }        

        try {
            const response = await axios.post('https://api.mymeme.exchange/coins', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200 || response.status === 201) {
                navigate(`/coin/${response.data.id}`);
            } else {
                setError('Failed to create coin');
            }
        } catch (error) {
            console.error('Error creating coin:', error);
            setError('An error occurred while creating the coin. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="space-y-6 p-2 rounded-xl">
            <h2 className="text-2xl font-bold text-[#F7931A] mb-4">Create MEME</h2>
            {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">{error}</div>}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="name" className="block mb-1 text-[#F7931A]">
                        Name                        
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#F7931A]"
                    />
                </div>
                <div>
                    <label htmlFor="symbol" className="block mb-1 text-[#F7931A]">
                        Symbol                        
                    </label>
                    <input
                        type="text"
                        id="symbol"
                        name="symbol"
                        value={formData.symbol}
                        onChange={handleChange}
                        required
                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#F7931A]"
                    />
                </div>
                <div>
                    <label htmlFor="description" className="block mb-1 text-[#F7931A]">
                        Description                        
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#F7931A]"
                    ></textarea>
                </div>
                <div>
                    <label htmlFor="icon" className="block mb-1 text-[#F7931A]">
                        Icon                        
                    </label>
                    <input
                        type="file"
                        id="icon"
                        name="icon"
                        onChange={handleFileChange}
                        accept="image/*"
                        required
                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#F7931A]"
                    />
                </div>

                <div className="mb-4">
                <button
                    type="button"
                    onClick={() => setShowMoreInputs(!showMoreInputs)}
                    className="w-full bg-orange-200 hover:bg-orange-300 text-[#F7931A] font-bold py-2 px-4 rounded-lg transition-colors duration-200"
                >
                    {showMoreInputs ? 'Hide Options' : 'Show More Options'}
                </button>
            </div>

            {showMoreInputs && (
                    <>
                        <div className="mb-4">
                            <label htmlFor="image" className="block mb-1 text-[#F7931A]">
                                Image (Optional)
                            </label>
                            <input
                                type="file"
                                id="image"
                                name="image"
                                onChange={handleFileChange}
                                accept="image/*"
                                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#F7931A]"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="initialPurchase" className="block mb-1 text-[#F7931A]">
                                Initial Purchase (min $0.01)
                            </label>
                            <input
                                type="number"
                                id="initialPurchase"
                                name="initialPurchase"
                                value={formData.initialPurchase}
                                onChange={handleChange}
                                min="0.01"
                                step="0.01"
                                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#F7931A]"
                            />
                        </div>
                    </>
                )}



                <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full bg-[#F7931A] hover:bg-[#E87D0D] text-white font-bold py-3 px-4 rounded-lg transition-colors duration-200 disabled:bg-orange-300"
                >
                    {isLoading ? 'Creating...' : 'Create MEME'}
                </button>
            </form>
        </div>
    );
};

export default CreateCoin;
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../UserContext';

const Internship = () => {
  const { token } = useUser();
  const [data, setData] = useState({
    internship: null,
    rewards: null,
    tasks: null,
    twitterAuthUrl: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('tasks');

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        setIsLoading(false);
        return;
      }
      try {
        const response = await axios.get('https://api.mymeme.exchange/internship', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('Data: ', response.data);
        setData({
          internship: response.data.internship,
          tasks: response.data.tasks,
          rewards: response.data.rewards,
          twitterAuthUrl: response.data.authUrl,
        });
        setError(null);
      } catch (err) {
        console.error('Error fetching internship data:', err);
        setError('Failed to load internship data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleTwitterConnect = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(data.twitterAuthUrl);
    } else {
      window.open(data.twitterAuthUrl, '_blank');
    }
  };

  if (isLoading) return <div className="text-center text-[#F7931A] text-xl animate-pulse">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="space-y-4 p-2 rounded-xl">
      {/* Internship Information */}
      <div className="bg-white rounded-lg shadow-lg p-4">
        <div className="flex items-center mb-2">
          <img src="https://storage.mymeme.exchange/twitter.png" alt="Twitter" className="w-6 h-6 mr-2" />
          <h2 className="text-2xl font-bold text-[#F7931A]">Internship</h2>
        </div>
        {data.internship ? (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-[#F7931A]">MEME:</p>
              <p className="text-orange-500">{data.internship.memeBalance}</p>
            </div>
            <div>
              <p className="text-sm text-[#F7931A]">Username :</p>
              <p className="text-orange-500">{data.internship.twitterUsername}</p>
            </div>
            {/* <div>
              <p className="text-sm text-[#F7931A]">Affliate:</p>
              <p className="text-orange-500">{data.internship.memeBalance}</p>
            </div>
            <div>
              <p className="text-sm text-[#F7931A]">Progress:</p>
              <p className="text-orange-500">{data.internship.twitterUsername}</p>
            </div>             */}
          </div>
        ) : (
          <button
            onClick={handleTwitterConnect}
            className="w-full bg-[#F7931A] hover:bg-[#E87D0D] text-white font-bold py-2 px-4 rounded transition-colors duration-200"
          >
            Connect Twitter
          </button>
        )}
      </div>

      <div className="flex justify-center mb-4">
        <button
          onClick={() => setActiveTab('tasks')}
          className={`px-4 py-2 font-bold ${activeTab === 'tasks' ? 'text-white bg-[#F7931A]' : 'text-[#F7931A] bg-white'} rounded-l-lg transition-colors duration-200`}
        >
          Task
        </button>
        <button
          onClick={() => setActiveTab('rewards')}
          className={`px-4 py-2 font-bold ${activeTab === 'rewards' ? 'text-white bg-[#F7931A]' : 'text-[#F7931A] bg-white'} rounded-r-lg transition-colors duration-200`}
        >
          Reward
        </button>
      </div>

      <div className="space-y-3">
        {activeTab === 'tasks' && data.tasks && data.tasks.length > 0 && (
          <>
            {data.tasks.map((task) => (
                <div className={`rounded-lg shadow-lg overflow-hidden transition-colors duration-200 flex ${
                  task.status === 'pending' ? 'bg-yellow-50 hover:bg-yellow-100' : 
                  task.status === 'completed' ? 'bg-green-50 hover:bg-green-100' : 
                  'bg-white hover:bg-orange-100'
                }`}>
                  <div className="flex-grow p-4 flex items-center">
                    <div className="w-full">
                      <div className="flex items-center justify-between">
                        <div>
                          <span className={`text-base mr-2 ${
                            task.status === 'pending' ? 'text-yellow-600' :
                            task.status === 'completed' ? 'text-green-600' :
                            'text-[#F7931A]'
                          }`}>{task.title}</span>
                        </div>
                        <div className="text-right">
                          <p className={`text-sm font-semibold ${
                            task.status === 'pending' ? 'text-yellow-600' :
                            task.status === 'completed' ? 'text-green-600' :
                            'text-[#F7931A]'
                          }`}>{task.reward} MEME</p>
                        </div>
                      </div>
                      <p className={`text-sm ${
                        task.status === 'pending' ? 'text-yellow-500' :
                        task.status === 'completed' ? 'text-green-500' :
                        'text-orange-500'
                      }`}>{task.description}</p>
                    </div>
                  </div>
                </div>
            ))}
          </>
        )}

        {activeTab === 'rewards' && data.rewards && (
          <>
            {data.rewards.length > 0 ? (
              data.rewards.map((reward) => (
                <div
                  key={reward.id}
                  className="bg-white rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 flex"
                >
                  <div className="flex-grow p-4">
                    <div className="flex items-center justify-between">
                      <div>
                        <span className="font-bold text-[#F7931A] text-lg mr-2">{reward.coin.name}</span>
                        <p className="text-sm text-orange-500">{reward.createdAt}</p>
                      </div>
                      <div className="text-right">
                        <p className="text-sm font-semibold text-[#F7931A]">{reward.memeAmount} MEME</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Link to="/explore" className="block">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200">
                  <div className="p-4">
                    <p className="text-lg font-bold text-[#F7931A] mb-2">No rewards yet</p>
                    <p className="text-sm text-orange-500">Promote coins to earn rewards!</p>
                  </div>
                </div>
              </Link>
            )}
          </>
        )}
      </div>

    </div>
  );
};

export default Internship;
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeIcon, MagnifyingGlassIcon, FaceSmileIcon, BriefcaseIcon, WalletIcon } from '@heroicons/react/24/solid';
import { useUser } from '../UserContext';

const NavItem = ({ to, icon: Icon, label }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  
  return (
    <Link 
      to={to} 
      className={`flex flex-col items-center ${
        isActive 
          ? 'text-white' 
          : 'text-yellow-200 hover:text-white'
      }`}
    >
      <Icon className={`h-8 w-8 ${isActive ? 'animate-pulse' : ''}`} />
      <span className="text-xs mt-1">{label}</span>
    </Link>
  );
};

const Navbar = () => {
  const { userInfo } = useUser();

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-orange-600 shadow-lg">
      <div className="container mx-auto flex justify-around items-center py-3 px-4">
        <NavItem to="/dashboard" icon={HomeIcon} />
        <NavItem to="/explore" icon={MagnifyingGlassIcon} />
        <NavItem to="/internship" icon={BriefcaseIcon} />
        <NavItem to="/character" icon={FaceSmileIcon} />
        <NavItem to="/profile" icon={WalletIcon} />        
      </div>
    </nav>
  );
};

export default Navbar;
import React from 'react';
import { useLocation, Link, useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../UserContext';
import { formatMillisatsForDisplay } from '../utils/swap';

const Topbar = ({ onOpenDrawer }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userInfo } = useUser();
    const { coinId } = useParams();

    const getPageTitle = () => {
        // const path = location.pathname.slice(1);
        // if (path.startsWith('coin/')) {
        //     return 'Coin';
        // }
        // if (path.startsWith('holdings/')) {
        //     return 'Bank';
        // }        
        // if (path === 'create-coin') {
        //     return 'Create MEME';
        // }
        // return path.charAt(0).toUpperCase() + path.slice(1);
        return "MEME";
    };
    const isCoinPage = location.pathname.startsWith('/coin/');

    return (
        <div className="fixed top-0 left-0 right-0 bg-orange-600 text-white p-3 shadow-lg z-50">
            <div className="container mx-auto flex justify-between items-center">
                <div className="flex items-center">
                    {isCoinPage && (
                        <button onClick={() => navigate(-1)} className="mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                            </svg>
                        </button>
                    )}
                    <h1 className="text-lg font-bold">{getPageTitle()}</h1>
                </div>
                <div className="flex space-x-2">
                    {isCoinPage ? (
                        <>                      
                            <button
                                onClick={() => onOpenDrawer('mint')}
                                className="bg-green-700 text-white px-4 py-1 rounded-full text-xs font-semibold hover:bg-green-300 transition-colors duration-200"
                            >
                                BUY
                            </button>
                            <button
                                onClick={() => onOpenDrawer('burn')}
                                className="bg-red-700 text-white px-4 py-1 rounded-full text-xs font-semibold hover:bg-red-300 transition-colors duration-200"
                            >
                                SELL
                            </button>
                        </>
                    ) : (
                        <>
                            <Link to="/vault" className="bg-white text-orange-500 px-2 py-1 rounded-full text-xs font-semibold hover:bg-orange-100 transition-colors duration-200">
                                ${userInfo.centBalance === 0 ? '0.00' : (userInfo.centBalance / 100).toFixed(2)}
                            </Link>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Topbar;

import BN from 'bn.js';

const oneBillion = '1000000000';
const threeMillion = '3000000'


const oriLevel0Constant = new BN(oneBillion).mul(new BN(threeMillion));


export function calculateMint(oldReserve, additionalCent) {
    if (isNaN(additionalCent) || typeof additionalCent !== 'number') {
        return "0";
    }
    const k = oriLevel0Constant;
    oldReserve = new BN(oldReserve);
    
    let deltaCent = new BN(additionalCent)
    let nettDeltaCent = deltaCent;

    // Calculate newReserve using the equation: oldReserve * oldVirtualCent = newReserve * newVirtualCent
    // where newVirtualCent = oldVirtualCent + deltaCent
    let oldVirtualCent = k.div(oldReserve);
    let newVirtualCent = oldVirtualCent.add(nettDeltaCent);
    let newReserve = k.div(newVirtualCent);
    let mintAmount = oldReserve.sub(newReserve);
    return mintAmount.toString();
}

export function calculateBurn(oldReserve, oldVirtualCent, additionalCoin) {
    if (isNaN(additionalCoin) || typeof additionalCoin !== 'number') {
        return "0";
    }    
    const k = oriLevel0Constant;
    oldReserve = new BN(oldReserve);
    oldVirtualCent = new BN(oldVirtualCent);
    additionalCoin = new BN(additionalCoin);

    // Calculate newVirtualCent using the equation: oldReserve * oldVirtualCent = newReserve * newVirtualCent
    // where newReserve = oldReserve + additionalCoin
    let newReserve = oldReserve.add(additionalCoin);
    let newVirtualCent = k.div(newReserve);
    let deltaVirtualCent = newVirtualCent.sub(oldVirtualCent);
    return deltaVirtualCent.toString();
}

export function calculateProgress(supply) {
    const perce = supply === 0 ? 0.00 : (supply / 1_000_000_000 * 100).toFixed(2);
    return perce;
}

export function calculatePricePerCoin(reserve, virtualCent) {
    let price = virtualCent / reserve;
    console.log(price)
    return price;
}

export function calculateCoinPerDollar(reserve, virtualCent) {
    const coinAmount = Math.floor(reserve / virtualCent * 100);
    return coinAmount;
}

export function calculatePricePerCent(reserve, virtualCent) {
    const pricePerCoin = calculatePricePerCoin(reserve, virtualCent)
    const price = 1 / pricePerCoin;
    return price.toFixed(0);
}
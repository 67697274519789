import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './UserContext';

import Intro from './pages/Intro';
import Unauthorised from './pages/Unauthorised';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Explore from './pages/Explore';
import Vault from './pages/Vault';

import Internship from './pages/Internship';
import InternshipTask from './pages/InternshipTask';

import CreateCoin from './pages/CreateCoin';
import Characters from './pages/Characters';
import Character from './pages/Character';

import Coins from './pages/Coins';
import Coin from './pages/Coin';

import DepositDetail from './pages/DepositDetail';
import WithdrawalDetail from './pages/WithdrawalDetail';

import Holdings from './pages/Holdings';
import Holding from './pages/Holding';

import Posts from './pages/Posts';
import Post from './pages/Post';


import Predictions from './pages/Predictions';
import Prediction from './pages/Prediction';

import Layout from './components/Layout';

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Intro />} />
          <Route path="/unauthorised" element={<Unauthorised />} />
          <Route path="/dashboard" element={<Layout><Dashboard /></Layout>} />
          <Route path="/profile" element={<Layout><Profile /></Layout>} />
          <Route path="/explore" element={<Layout><Explore /></Layout>} />
          <Route path="/vault" element={<Layout><Vault /></Layout>} />
          
          <Route path="/internship" element={<Layout><Internship /></Layout>} />
          <Route path="/internship-task/:id" element={<Layout><InternshipTask /></Layout>} />

          <Route path="/character" element={<Layout><Characters /></Layout>} />
          <Route path="/character/:id" element={<Layout><Character /></Layout>} />          

          <Route path="/coin" element={<Layout><Coins /></Layout>} />
          <Route path="/coin/:id" element={<Layout><Coin /></Layout>} />
          <Route path="/create-coin" element={<Layout><CreateCoin /></Layout>} />

          <Route path="/deposit/:id" element={<Layout><DepositDetail /></Layout>} /> 
          <Route path="/withdrawal/:id" element={<Layout><WithdrawalDetail /></Layout>} /> 
          
          <Route path="/holding" element={<Layout><Holdings /></Layout>} />                  
          <Route path="/holding/:id" element={<Layout><Holding /></Layout>} />

          <Route path="/post" element={<Layout><Posts /></Layout>} />                  
          <Route path="/post/:id" element={<Layout><Post /></Layout>} />                

          <Route path="/prediction" element={<Layout><Predictions /></Layout>} />                  
          <Route path="/prediction/:id" element={<Layout><Prediction /></Layout>} />                            
                    
        </Routes>
      </Router>
    </UserProvider>

  );
};

export default App;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../UserContext';

const WithdrawalDetail = () => {
  const [withdrawal, setWithdrawal] = useState(null);
  const [error, setError] = useState(null);
  const { token } = useUser();
  const { id } = useParams();

  useEffect(() => {
    if (!token) {
      return;
    }

    const fetchWithdrawal = async () => {
      try {
        const response = await axios.get(`https://api.mymeme.exchange/withdrawals/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setWithdrawal(response.data);
      } catch (err) {
        setError('Failed to fetch withdrawal details');
        console.error(err);
      }
    };

    fetchWithdrawal();
  }, [id, token]);

  if (error) return <div>Error: {error}</div>;
  if (!withdrawal) return <div>Loading...</div>;

  return (
    <div className="space-y-6 p-2 rounded-xl">
      <div className="bg-white rounded-lg shadow-lg p-4 hover:bg-orange-100 transition-colors duration-200">
        <div className="flex items-center mb-2">
          <h2 className="text-2xl font-bold text-[#F7931A]">Withdrawal Detail</h2>
        </div>
        <div className="space-y-2">
          <div>
            <p className="text-sm text-[#F7931A]">ID</p>
            <p className="text-lg font-bold text-[#F7931A]">{withdrawal.id}</p>
          </div>
          <div>
            <p className="text-sm text-[#F7931A]">Status</p>
            <p className="text-lg font-bold text-[#F7931A]">{withdrawal.status}</p>
          </div>
          <div>
            <p className="text-sm text-[#F7931A]">Reference</p>
            <p className="text-lg font-bold text-[#F7931A]">{withdrawal.reference}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalDetail;
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../UserContext';

const Characters = () => {
  const [tabValue, setTabValue] = useState(0);
  const [characters, setCharacters] = useState([]);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useUser();
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchCharactersData = async () => {
      if (!token) {
        setIsLoading(false);
        return;
      }
      try {
        const response = await axios.get('https://api.mymeme.exchange/characters', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('Data: ', response.data)
        setCharacters(response.data.characters || []);
        setPosts(response.data.posts || []);
        setError(null);
      } catch (err) {
        console.error('Error fetching characters data:', err);
        setError('Failed to load characters data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCharactersData();
  }, [token]);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  if (isLoading) return <div className="text-center text-[#F7931A] text-xl animate-pulse">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="space-y-4 p-2 rounded-xl">

      <div className="flex justify-center mb-4">
        <button
          onClick={() => handleTabChange(0)}
          className={`px-4 py-2 font-bold ${tabValue === 0 ? 'text-white bg-[#F7931A]' : 'text-[#F7931A] bg-white'} rounded-l-lg transition-colors duration-200`}
        >
          Timeline
        </button>
        <button
          onClick={() => handleTabChange(1)}
          className={`px-4 py-2 font-bold ${tabValue === 1 ? 'text-white bg-[#F7931A]' : 'text-[#F7931A] bg-white'} rounded-r-lg transition-colors duration-200`}
        >
          Character
        </button>
      </div>

      <div className="space-y-3">
        {tabValue === 0 && posts.map(post => (
          <div key={post.id} className="bg-white rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 flex flex-col cursor-pointer" onClick={() => navigate(`/post/${post.id}`)}>
            <div className="w-full h-40">
              <img src={post.imageUrl} alt={post.character.name} className="w-full h-full object-cover" />
            </div>
            <div className="p-4">
              <h3 className="font-bold text-[#F7931A] text-lg">{post.character.name}</h3>
              <p className="text-sm text-orange-500">{post.text}</p>
              <p className="text-xs text-orange-400 mt-1">{formatDate(post.createdAt)}</p>
            </div>
          </div>
        ))}

        {tabValue === 1 && characters.map(character => (
          
          
            <Link
              key={character.id}
              to={`/character/${character.id}`}
              className="bg-white rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 flex"
            >
              <div className="w-20 h-20 flex-shrink-0">
                <img
                  src={character.imageUrl}
                  alt={character.name}
                  className="w-full h-full object-cover rounded-l-lg"
                />
              </div>
              <div className="flex-grow p-4 flex items-center">
                <div className="w-full">
                  <div className="flex items-center justify-between">
                    <div>
                      <span className="text-[#F7931A] text-base mr-2">{character.name}</span>
                      <p className="text-sm text-orange-500">
                        {character.symbol}  | {character.postCount} posts | {character.coinCount} coins
                      </p>
                    </div>
                  </div>
                   
                </div>
              </div>
            </Link>


        ))}
      </div>

    </div>
  );
};

export default Characters;
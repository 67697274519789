import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../UserContext';

const Dashboard = () => {
  const { token, userInfo, updateUserInfo } = useUser();
  const [dashboardData, setDashboardData] = useState({
    randomCoins: [],
    pendingDeposit: null,
    jokerCoin: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dataFetchedRef = useRef(false);


  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchDashboardData = async () => {
      if (!token) {
        setIsLoading(false);
        return;
      }
      try {
        const response = await axios.get('https://api.mymeme.exchange/dashboard', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('Data: ', response.data)
        setDashboardData({
          jokerCoin: response.data.jokerCoin,
          pendingDeposit: response.data.pendingDeposit || [],
          randomCoins: response.data.randomCoins || []
        });

        updateUserInfo({
          userId: response.data.user.id,
          telegramId: response.data.user.telegramId,
          telegramUsername: response.data.user.telegramUsername,
          centBalance: response.data.user.centBalance,
          memeBalance: response.data.user.memeBalance,
        });

        setError(null);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load dashboard data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, [token, updateUserInfo]);

  if (isLoading) return <div className="text-center text-[#F7931A] text-xl animate-pulse">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="space-y-6 p-2 rounded-xl">


      {/* Joker Coin Card */}
      {dashboardData.jokerCoin && (
        <Link
          to={`/coin/${dashboardData.jokerCoin.id}`}
        >
          <div className="bg-white rounded-lg shadow-lg mb-4 overflow-hidden">
            <div className="h-48 overflow-hidden">
              <img
                src={dashboardData.jokerCoin.characterId ? dashboardData.jokerCoin.imageUrl : "https://storage.mymeme.exchange/qkFSi8Phh1OF6EAwxhT4O_86ad95ef532c4b9995fc8ebf49abda63.jpg"}
                alt={dashboardData.jokerCoin.name}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-4">
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="text-xl font-bold text-[#F7931A]">{dashboardData.jokerCoin.name}</h4>  
                </div>
                <div className="text-right">
                  <h4 className="text-xl font-bold text-[#F7931A]">
                    {dashboardData.jokerCoin.supply > 0
                      ? ((dashboardData.jokerCoin.supply / 1_000_000_000) * 100).toFixed(2)
                      : '0.00'}%
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}

      {/* Create a Meme Button */}
      <Link to="/create-coin" className="block w-full mb-4">
        <button className="w-full bg-[#F7931A] hover:bg-[#E87D0D] text-white font-bold py-3 px-4 rounded-lg transition-colors duration-200">
          Create MEME
        </button>
      </Link>

      
      <div>
        <h2 className="text-2xl font-bold text-[#F7931A] mb-3">MEMEs</h2>
        {dashboardData.randomCoins && dashboardData.randomCoins.length > 0 ? (
          <div className="space-y-3">
            {dashboardData.randomCoins.slice(0, 5).map((coin) => (
              <Link
                key={coin.id}
                to={`/coin/${coin.id}`}
                className="bg-white rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 flex"
              >
                <div className="w-20 h-20 flex-shrink-0">
                  <img
                    src={coin.iconUrl}
                    alt={coin.name}
                    className="w-full h-full object-cover rounded-l-lg"
                  />
                </div>
                <div className="flex-grow p-4 flex items-center">
                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <div>
                        <span className="text-[#F7931A] text-base mr-2">{coin.name} </span>
                        <p className="text-sm text-orange-500">
                          {coin.userId ? `Created by: @${coin.user.telegramUsername}` : coin.characterId ? `By ${coin.character.name}` : ''}
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="text-sm font-semibold text-[#F7931A]">
                          {coin.supply > 0
                            ? ((coin.supply / 1_000_000_000) * 100).toFixed(2)
                            : '0.00'}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-lg p-4 text-center">
            <p className="text-[#F7931A]">Check back later for exciting suggestions!</p>
          </div>
        )}
      </div>


    </div>
  );
};

export default Dashboard;
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../UserContext';


const Explore = () => {
    const { token } = useUser();
    const [coins, setCoins] = useState({
        newCoins: [],
        popularCoins: [],
        starterCoins: [],
        jokerCoins: []
    });
    const [activeTab, setActiveTab] = useState('newCoins');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        const fetchExploreData = async () => {
            if (!token) {
                setIsLoading(false);
                return;
            }
            try {
                const response = await axios.get('https://api.mymeme.exchange/explore', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setCoins({
                    newCoins: response.data.newCoins || [],
                    popularCoins: response.data.popularCoins || [],
                    starterCoins: response.data.starterCoins || [],
                    jokerCoins: response.data.jokerCoins || []
                });
                setError(null);
            } catch (err) {
                console.error('Error fetching explore data:', err);
                setError('Failed to load explore data. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchExploreData();
    }, [token]);

    if (isLoading) return <div className="text-center text-[#F7931A] text-xl animate-pulse">Loading...</div>;
    if (error) return <div className="text-center text-red-500">{error}</div>;

    const tabs = [
        { key: 'newCoins', label: 'New' },
        { key: 'popularCoins', label: 'Popular' },
        { key: 'starterCoins', label: 'Starter' },
        { key: 'jokerCoins', label: 'Joker' }
    ];

    return (
        <div className="p-4">
            <div className="flex justify-center mb-4">
                {tabs.map(tab => (
                    <button
                        key={tab.key}
                        onClick={() => setActiveTab(tab.key)}
                        className={`px-4 py-2 font-bold ${activeTab === tab.key ? 'text-white bg-[#F7931A]' : 'text-[#F7931A] bg-white'} rounded-lg transition-colors duration-200 mx-1`}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {coins[activeTab].map((coin) => (
                            <Link
                            key={coin.id}
                            to={`/coin/${coin.id}`}
                            className="bg-white rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 flex"
                        >
                            <div className="w-20 h-20 flex-shrink-0">
                                <img
                                    src={coin.iconUrl}
                                    alt={coin.name}
                                    className="w-full h-full object-cover rounded-l-lg"
                                />
                            </div>
                            <div className="flex-grow p-4 flex items-center">
                                <div className="w-full">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <span className="text-[#F7931A] text-base mr-2">{coin.name} </span>
                                            <p className="text-sm text-orange-500">
                                                {coin.userId ? `Created by: @${coin.user.telegramUsername}` : coin.characterId ? `By ${coin.character.name}` : ''}
                                            </p>
                                        </div>
                                        <div className="text-right">
                                            <p className="text-sm font-semibold text-[#F7931A]">
                                                {coin.supply > 0
                                                    ? ((coin.supply / 1_000_000_000) * 100).toFixed(2)
                                                    : '0.00'}%
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>                    
                ))}
            </div>
        </div>
    );
};

export default Explore;
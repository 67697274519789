import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../UserContext';

const Post = () => {
    const [post, setPost] = useState(null);
    const [coin, setCoin] = useState(null);
    const [character, setCharacter] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const { token } = useUser();

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`https://api.mymeme.exchange/posts/${id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (!response.data) {
                    throw new Error('Failed to fetch post data');
                }
                console.log(response.data);
                setPost(response.data.post);
                setCoin(response.data.coin)
                setCharacter(response.data.character)
            } catch (error) {
                console.error('Error fetching post:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPost();
    }, [id, token]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!post) {
        return <div>Post not found</div>;
    }

    return (
        <div className="post-page">
            {/* Post details */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 mb-4">
                <div className="w-full h-64">
                    <img
                        src={post.imageUrl}
                        alt={character.name}
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="p-4">
                    <Link className="font-bold text-[#F7931A] text-xl mb-2" to={`/character/${character.id}`}>{character.name}</Link>
                    <p className="text-sm text-orange-500">{post.text}</p>
                    <p className="text-xs text-orange-400 mt-2">{new Date(post.createdAt).toLocaleString()}</p>
                </div>
            </div>

            {/* Coin details */}
            {coin && (
                <Link
                    to={`/coin/${coin.id}`}
                    className="bg-white mb-4 rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 flex"
                >
                    <div className="w-20 h-20 flex-shrink-0">
                        <img
                            src={coin.iconUrl}
                            alt={coin.name}
                            className="w-full h-full object-cover rounded-l-lg"
                        />
                    </div>
                    <div className="flex-grow p-4 flex items-center">
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <div>
                                    <span className="text-[#F7931A] text-base mr-2">{coin.name} </span>
                                    <p className="text-sm text-orange-500 mt-1">
                                        By {character.name}
                                    </p>
                                </div>
                                <div className="text-right">
                                    <p className="text-sm font-semibold text-[#F7931A]">
                                        {coin.supply > 0
                                            ? ((coin.supply / 1_000_000_000) * 100).toFixed(2)
                                            : '0.00'}%
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            )}
        </div>
    );
};

export default Post;
import React from 'react';
import Navbar from './Navbar';
import Topbar from './Topbar';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Topbar />
      <main className="flex-grow container mx-auto p-2 mt-12 mb-16 bg-yellow-100 overflow-y-auto">
        {children}
      </main>
      <Navbar />
    </div>
  );
};

export default Layout;
import React, { useState } from 'react';
import { calculateMint, calculateBurn } from '../utils/swap';
import { useUser } from '../UserContext';

const CoinDrawer = ({ isOpen, onClose, type, user, coin, myHolding }) => {
    const [amount, setAmount] = useState('');
    const [estimatedOutput, setEstimatedOutput] = useState('');
    const { token } = useUser();

    const [error, setError] = useState('');


    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setAmount(inputValue);

        if (coin) {

            if (type === 'mint') {
                const dollarInput = parseFloat(inputValue);
                const centInput = parseInt(dollarInput * 100);
                const coinsToMint = calculateMint(coin.reserve, centInput);
                console.log(coinsToMint);
                setEstimatedOutput(coinsToMint);
            } else {
                const coinInput = parseInt(inputValue)
                const dollarToReceive = calculateBurn(coin.reserve, coin.virtualCent, coinInput);
                setEstimatedOutput(dollarToReceive.toString());
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // TODO: Implement mint or burn logic here
        console.log(`${type} ${amount} of coin ${coin.id}`);
        onClose();
    };

    if (!isOpen || !coin) return null;


    return (
        <div className="fixed inset-0 overflow-hidden z-50">
            <div className="absolute inset-0 overflow-hidden">
                <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose}></div>
                <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                    <div className="w-screen max-w-md">
                        <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                            <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                                <div className="flex items-start justify-between">
                                    <h2 className="text-lg font-medium text-gray-900">
                                        {coin.name}
                                    </h2>
                                    <button
                                        type="button"
                                        className="text-gray-400 hover:text-gray-500"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Close panel</span>
                                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="mt-8">
                                    <div className="mb-4">
                                        <div className="space-y-2">
                                            {type === 'mint' ? (
                                                <>
                                            <div className="flex justify-between items-center">
                                                <span className="text-sm text-gray-500">Balance:</span>
                                                <span className="text-lg font-bold">{user.centBalance}</span>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <span className="text-sm text-gray-500">Supply:</span>
                                                <span className="text-lg font-bold">{coin.supply}</span>
                                            </div>                                                
                                                </>
                                            ) : (
                                                <>
                                                    <div className="flex justify-between items-center">
                                                        <span className="text-sm text-gray-500">Balance:</span>
                                                        <span className="text-lg font-bold">{myHolding.coinAmount}</span>
                                                    </div>
                                                    <div className="flex justify-between items-center">
                                                        <span className="text-sm text-gray-500">Supply:</span>
                                                        <span className="text-lg font-bold">{coin.supply}</span>
                                                    </div>
                                                </>
                                            )}

                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit}>

                                        {type === 'mint' ? (
                                            <><div className="mt-6">
                                                <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                                                    $
                                                </label>

                                                <div className="mt-1">
                                                    <input
                                                        type="number"
                                                        name="amount"
                                                        id="amount"
                                                        className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                        value={amount}
                                                        onChange={handleInputChange}
                                                        required
                                                        min='0.01'
                                                        max='1000.00' />
                                                </div>
                                            </div><div className="mt-4">
                                                    <label htmlFor="estimatedOutput" className="block text-sm font-medium text-gray-700">
                                                        Estimated Coin:
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            type="text"
                                                            name="estimatedOutput"
                                                            id="estimatedOutput"
                                                            className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-100"
                                                            value={estimatedOutput}
                                                            disabled />
                                                    </div>
                                                </div><div className="mt-6">
                                                    <button
                                                        type="submit"
                                                        className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:ring-green-500`}
                                                    >
                                                        BUY
                                                    </button>
                                                </div></>
                                        ) : (
                                            <div>
                                                <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                                                    Amount to Burn
                                                </label>
                                                <input
                                                    type="number"
                                                    name="amount"
                                                    id="amount"
                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    required
                                                />
                                            </div>
                                        )}


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default CoinDrawer;

// <div className="mt-6">                                        
// <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
//     {type === 'mint' ? '$' : 'Coin'}
// </label>

// <div className="mt-1">
//     <input
//         type="number"
//         name="amount"
//         id="amount"
//         className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md"
//         value={amount}
//         onChange={handleInputChange}
//         required
//         min={type === 'mint' ? '0.01' : '1'}
//         max={type === 'mint' ? '1000.00' : '100000000'}
//     />
// </div>
// </div>
// <div className="mt-4">
// <label htmlFor="estimatedOutput" className="block text-sm font-medium text-gray-700">
//     Estimated {type === 'mint' ? 'Coin:' : '$:'}
// </label>
// <div className="mt-1">
//     <input
//         type="text"
//         name="estimatedOutput"
//         id="estimatedOutput"
//         className="shadow-sm focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-100"
//         value={estimatedOutput}
//         disabled
//     />
// </div>
// </div>
// <div className="mt-6">
// <button
//     type="submit"
//     className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${type === 'mint' ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500' : 'bg-red-600 hover:bg-red-700 focus:ring-red-500'}`}
// >
//     {type === 'mint' ? 'BUY' : 'SELL'}
// </button>
// </div>
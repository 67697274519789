import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../UserContext'
import { useParams, Link } from 'react-router-dom';
import Topbar from '../components/Topbar';
import CoinDrawer from '../components/CoinDrawer';
import { calculateProgress, calculatePricePerCoin, calculateCoinPerDollar } from "../utils/swap";

const Coin = () => {
    const { id } = useParams();
    const { token, userInfo } = useUser();
    const [coin, setCoin] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [holdings, setHoldings] = useState(null);
    const [link, setLink] = useState(null);
    const [myHolding, setMyHolding] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerType, setDrawerType] = useState(null);
    const [copySuccess, setCopySuccess] = useState(false);

    useEffect(() => {
        const fetchCoin = async () => {
            try {
                const response = await axios.get(`https://api.mymeme.exchange/coins/${id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                console.log('Coin data:', response.data);
                setLink(response.data.linkUrl);
                setCoin(response.data.coin);
                setHoldings(response.data.holdings);
                setMyHolding(response.data.myHolding);
            } catch (err) {
                console.error('Error fetching coin data:', err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data.message : err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCoin();
    }, [id, token]);

    const handleOpenDrawer = (type) => {
        setDrawerType(type);
        setDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setDrawerType(null);
    };

    const handleShareLink = async () => {
        try {
            await navigator.clipboard.writeText(link);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    if (loading) return <div className="text-center text-purple-600 text-xl animate-pulse">Loading...</div>;
    if (error) return <div className="text-center text-red-500">{error}</div>;

    return (
        <>
            <Topbar onOpenDrawer={handleOpenDrawer} />
            <div className="space-y-6 p-2 rounded-xl">
                {/* Coin Image */}
                {coin.imageUrl && (
                    <div className="bg-white rounded-lg shadow-lg mb-4 overflow-hidden">
                        <div className="h-48 overflow-hidden">
                            <img
                                src={coin.characterId ? coin.imageUrl : "https://storage.mymeme.exchange/qkFSi8Phh1OF6EAwxhT4O_86ad95ef532c4b9995fc8ebf49abda63.jpg"}
                                alt={coin.name}
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <div className="p-4">
                            <div className="flex justify-between items-start">
                                <div>
                                    <h4 className="text-xl font-bold text-[#F7931A]">{coin.name}</h4>
                                    <h4 className="text-lg text-[#F7931A]">
                                        {coin.characterId ? `By ${coin.character.name}` : coin.userId ? `Created by @${coin.user.telegramUsername}` : 'N/A'}
                                    </h4>
                                </div>

                            </div>
                        </div>
                    </div>
                )}

                <div className="flex justify-center mb-4">
                    <button
                        onClick={() => setTabValue(0)}
                        className={`px-4 py-2 font-bold ${tabValue === 0 ? 'text-white bg-[#F7931A]' : 'text-[#F7931A] bg-white'} rounded-l-lg transition-colors duration-200`}
                    >
                        About
                    </button>
                    <button
                        onClick={() => setTabValue(1)}
                        className={`px-4 py-2 font-bold ${tabValue === 1 ? 'text-white bg-[#F7931A]' : 'text-[#F7931A] bg-white'} rounded-none transition-colors duration-200`}
                    >
                        Holder
                    </button>
                    <button
                        onClick={() => setTabValue(2)}
                        className={`px-4 py-2 font-bold ${tabValue === 2 ? 'text-white bg-[#F7931A]' : 'text-[#F7931A] bg-white'} rounded-r-lg transition-colors duration-200`}
                    >
                        Swap
                    </button>
                </div>
                <div className="space-y-3">
                    {tabValue === 0 && (
                        <>
                            {/* Coin Details - Second Card */}
                            < div className="bg-white rounded-lg shadow-lg p-4">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <p className="text-sm text-[#F7931A]">Supply</p>
                                        <p className="text-lg font-bold text-[#F7931A]">{(coin.supply).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-[#F7931A]">Progress, %</p>
                                        <p className="text-lg font-bold text-[#F7931A]">{calculateProgress(coin.supply)}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-[#F7931A]">Price, m$</p>
                                        <p className="text-lg font-bold text-[#F7931A]">{Math.floor(calculatePricePerCoin(coin.reserve, coin.virtualCent) * 10_000)}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-[#F7931A]">Coin per $</p>
                                        <p className="text-lg font-bold text-[#F7931A]">{calculateCoinPerDollar(coin.reserve, coin.virtualCent)}</p>
                                    </div>
                                </div>
                            </div>

                            < div className="bg-white rounded-lg shadow-lg p-4">

                                <p className="text-[#F7931A]">{coin.description}</p>
                            </div>

                            {/* Share Link Button */}
                            <button
                                onClick={handleShareLink}
                                className="w-full bg-[#F7931A] hover:bg-[#E87D0D] text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                            >
                                {copySuccess ? 'Copied!' : 'Share MEME'}
                            </button>
                        </>
                    )}
                    {tabValue === 2 && (
                        <>
                            {/* Recent Swaps */}
                            <div>
                                {coin.recentSwaps && coin.recentSwaps.length > 0 ? (
                                    <div className="space-y-3">
                                        {coin.recentSwaps.map((swap, index) => (
                                            <div key={index} className="bg-white rounded-lg shadow-lg p-4 flex justify-between items-center hover:bg-orange-100 transition-colors duration-200">
                                                <div>
                                                    <p className="font-bold text-[#F7931A]">{swap.type}</p>
                                                    <p className="text-sm text-orange-500">{new Date(swap.date).toLocaleString()}</p>
                                                </div>
                                                <p className="text-lg font-bold text-orange-500">
                                                    {swap.amount}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="bg-white rounded-lg shadow-lg p-4 text-center">
                                        <p className="text-[#F7931A]">No recent swaps available. Be the first to swap!</p>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {tabValue === 1 && (
                        <>
                            {/* Holdings */}
                            <div>
                                {holdings && holdings.length > 0 ? (
                                    <div className="space-y-2">
                                        {holdings.map((holding, index) => (
                                            <div key={index} className="bg-white rounded-lg shadow-lg p-3 flex justify-between items-center hover:bg-orange-100 transition-colors duration-200">
                                                <p className="font-bold text-[#F7931A]">@{holding.user.telegramUsername}</p>
                                                <p className="text-orange-500">{holding.coinAmount}</p>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="bg-white rounded-lg shadow-lg p-4 text-center">
                                        <p className="text-[#F7931A]">No holdings data available yet.</p>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>








            </div >

            {/* CoinDrawer */}
            {
                drawerOpen && (
                    <CoinDrawer
                        isOpen={drawerOpen}
                        onClose={handleCloseDrawer}
                        type={drawerType}
                        coin={coin}
                        user={userInfo}
                        holdings={holdings}
                        myHolding={myHolding}
                    />
                )
            }
        </>
    );
};

export default Coin;
import React, { useState } from 'react';
import axios from 'axios';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';

const VaultDrawer = ({ isOpen, onClose, type, onSubmit }) => {
    const { token } = useUser();
    const navigate = useNavigate();
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const minAmount = type === 'deposit' ? 0.01 : 1.00;
        if (parseFloat(amount) < minAmount) {
            setError(`Minimum ${type} amount is ${minAmount} ${type === 'deposit' ? 'SOL' : 'USD'}`);
            return;
        }

        try {
            const endpoint = type === 'deposit' ? 'deposits' : 'withdrawals';
            const response = await axios.post(
                `https://api.mymeme.exchange/${endpoint}`,
                { amount: parseFloat(amount) },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data && response.status == 201) {
                onSubmit();
                onClose();
                // Update navigation based on transaction type
                const transactionId = response.data.id; // Assuming the API returns an ID
                navigate(type === 'deposit' ? `/deposit/${transactionId}` : `/withdrawal/${transactionId}`);
            } else {
                setError('Transaction failed. Please try again.');
            }
        } catch (err) {
            console.error(`Error during ${type}:`, err);
            console.log(err)
            setError(err.response?.data?.message || `Failed to process ${type}. Please try again later.`);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 overflow-hidden z-50">
            <div className="absolute inset-0 overflow-hidden">
                <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose}></div>
                <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                    <div className="w-screen max-w-md">
                        <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                            <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                                <div className="flex items-start justify-between">
                                    <h2 className="text-2xl font-bold text-[#F7931A]">{type.charAt(0).toUpperCase() + type.slice(1)}</h2>
                                    <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Close panel</span>
                                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>

                                <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                                    
                                        <div className="space-y-4">
                                            <div>
                                                <label htmlFor="amount" className="block text-sm font-medium text-[#F7931A]">
                                                    Amount ({type === 'deposit' ? 'SOL' : 'USD'})
                                                </label>
                                                <input
                                                    type="number"
                                                    id="amount"
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    placeholder={`Enter amount (min ${type === 'deposit' ? '0.01 SOL' : '$1.00'})`}
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#F7931A] focus:border-[#F7931A] sm:text-sm"
                                                    required
                                                    step={type === 'deposit' ? '0.01' : '0.01'}
                                                    min={type === 'deposit' ? '0.01' : '1.00'}
                                                />
                                            </div>
                                            {error && <p className="text-red-500 text-sm">{error}</p>}
                                            <button
                                                type="submit"
                                                className="w-full bg-[#F7931A] hover:bg-[#E87D0D] text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                                            >
                                                {type.charAt(0).toUpperCase() + type.slice(1)}
                                            </button>
                                        </div>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default VaultDrawer;
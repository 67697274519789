import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../UserContext';
import { PlusIcon, MinusIcon, WalletIcon } from '@heroicons/react/24/solid';
import VaultDrawer from '../components/VaultDrawer';

const Vault = () => {
  const { token } = useUser();
  const [vaultData, setVaultData] = useState({
    balance: 0,
    totalDeposit: 0,
    totalWithdrawal: 0,
    deposits: [],
    withdrawals: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState('');

  const fetchVaultData = async () => {
    if (!token) {
      setIsLoading(false);
      setError('Please log in to view your Vault data.');
      return;
    }
    try {
      const response = await axios.get('https://api.mymeme.exchange/vault', {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data && Object.keys(response.data).length > 0) {
        setVaultData({
          balance: response.data.user.centBalance,
          totalDeposit: response.data.totalDeposit,
          totalWithdrawal: response.data.totalWithdrawal,
          deposits: response.data.deposits,
          withdrawals: response.data.withdrawals,          
        });
        setError(null);
      } else {
        setError('No Vault data available.');
      }
    } catch (err) {
      console.error('Error fetching vault data:', err);
      setError(err.response?.data || 'Failed to load vault data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVaultData();
  }, [token]);

  const handleOpenDrawer = (type) => {
    setDrawerType(type);
    setShowDrawer(true);
  };

  if (isLoading) return <div className="text-center text-[#F7931A] text-xl animate-pulse">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="space-y-6 p-2 rounded-xl">
      <div className="bg-white rounded-lg shadow-lg p-4 hover:bg-orange-100 transition-colors duration-200">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-2xl font-bold text-[#F7931A]">Vault</h2>
          <WalletIcon className="h-6 w-6 text-[#F7931A]" />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <p className="text-sm text-[#F7931A]">Balance</p>
            <p className="text-lg font-medium text-orange-500">${vaultData.balance === 0 ? '0.00' : (vaultData.balance / 100).toFixed(2)}</p>
          </div>
          <div>
            <p className="text-sm text-[#F7931A]">Deposit</p>
            <p className="text-lg font-medium text-orange-500">{vaultData.totalDeposit}</p>
          </div>
          <div>
            <p className="text-sm text-[#F7931A]">Withdrawal</p>
            <p className="text-lg font-medium text-orange-500">{vaultData.totalWithdrawal}</p>
          </div>
        </div>
      </div>

      {/* Deposit/Withdraw Buttons */}
      <div className="flex justify-center space-x-4">
        <button
          onClick={() => handleOpenDrawer('deposit')}
          className="flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#F7931A] hover:bg-[#E87D0D] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F7931A] transition-colors duration-200"
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          Deposit
        </button>
        <button
          onClick={() => handleOpenDrawer('withdraw')}
          className="flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#F7931A] hover:bg-[#E87D0D] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F7931A] transition-colors duration-200"
        >
          <MinusIcon className="h-5 w-5 mr-2" />
          Withdraw
        </button>
      </div>

      {(vaultData.deposits && vaultData.deposits.length > 0) && (
        <div>
          <h2 className="text-2xl font-bold text-[#F7931A] mb-3">Deposits</h2>
          <div className="space-y-3">
            {vaultData.deposits.map((deposit, index) => (
              <Link key={index} to={`/deposit/${deposit.id}`} className="bg-white rounded-lg shadow-lg p-4 flex justify-between items-center hover:bg-orange-100 transition-colors duration-200">
                {deposit.status === 'pending' && (
                  <div>
                    <p className="font-bold text-[#F7931A]">Status: {deposit.status.charAt(0).toUpperCase() + deposit.status.slice(1)}</p>
                  </div>
                )}
                {deposit.status === 'consolidated' && (
                  <div>
                    <p className="font-bold text-[#F7931A]">Amount: {deposit.amount} SOL</p>
                    <p className="text-sm text-orange-500">Status: {deposit.status.charAt(0).toUpperCase() + deposit.status.slice(1)}</p>
                  </div>
                )}
                {deposit.status === 'expired' && (
                  <div>
                    <p className="text-sm text-orange-500">Status: {deposit.status.charAt(0).toUpperCase() + deposit.status.slice(1)}</p>
                  </div>
                )}
                {deposit.status === 'insufficient' && (
                  <div>
                    <p className="font-bold text-[#F7931A]">Amount: {deposit.amount} SOL</p>
                    <p className="text-sm text-orange-500">Status: {deposit.status.charAt(0).toUpperCase() + deposit.status.slice(1)}</p>
                  </div>
                )}
              </Link>
            ))}
          </div>
        </div>
      )}
      {(vaultData.withdrawals && vaultData.withdrawals.length > 0) && (
        <div>
          <h2 className="text-2xl font-bold text-[#F7931A] mb-3">Withdrawals</h2>
          <div className="space-y-3">
            {vaultData.withdrawals.map((withdrawal, index) => (
              <Link key={index} to={`/withdrawal/${withdrawal.id}`} className="bg-white rounded-lg shadow-lg p-4 flex justify-between items-center hover:bg-orange-100 transition-colors duration-200">
                <div>
                  <p className="font-bold text-[#F7931A]">Amount: ${withdrawal.amount / 100}</p>
                  <p className="text-sm text-orange-500">Status: {withdrawal.status}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}


      {/* Vault Drawer */}
      {showDrawer && (
        <VaultDrawer
          isOpen={showDrawer}
          onClose={() => setShowDrawer(false)}
          type={drawerType}
          onSubmit={fetchVaultData}
        />
      )}
    </div>
  );
};

export default Vault;
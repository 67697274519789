import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../UserContext';
import axios from 'axios';

const Profile = () => {
  const { token } = useUser();
  const [data, setData] = useState({
    user: {},
    toBuyCoins: [],
    coinHoldings: [],
    profileLink: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        setIsLoading(false);
        return;
      }
      try {
        const [profileResponse] = await Promise.all([
          axios.get('https://api.mymeme.exchange/profile', {
            headers: { Authorization: `Bearer ${token}` }
          }),
        ]);

        console.log('Data: ', profileResponse.data)
        setData({
          user: profileResponse.data.user,
          profileLink: profileResponse.data.profileLink,
          coinHoldings: profileResponse.data.coinHoldings,
          toBuyCoins: profileResponse.data.toBuyCoins,
        });
        setError(null);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleShareLink = async () => {
    try {
      await navigator.clipboard.writeText(data.profileLink);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  if (isLoading) return <div className="text-center text-[#F7931A] text-xl animate-pulse">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="space-y-4 p-2 rounded-xl">
      {/* Telegram Information */}
      <div className="bg-white rounded-lg shadow-lg p-4">
        <div className="flex items-center mb-2">
          <img src="https://storage.mymeme.exchange/telegram.png" alt="Telegram" className="w-6 h-6 mr-2" />
          <h2 className="text-2xl font-bold text-[#F7931A]">Profile</h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-[#F7931A]">Telegram ID:</p>
            <p className="text-orange-500">{data.user.telegramId}</p>
          </div>
          <div>
            <p className="text-sm text-[#F7931A]">Username:</p>
            <p className="text-orange-500">{data.user.telegramUsername}</p>
          </div>
          <div>
            <p className="text-sm text-[#F7931A]">On Hand:</p>
            <p className="text-orange-500">{data.coinHoldings && data.coinHoldings.length > 0 ? data.coinHoldings.length : 0}</p>
          </div>
          <div>
            <p className="text-sm text-[#F7931A]">Created:</p>
            <p className="text-orange-500">{data.coinHoldings && data.coinHoldings.length > 0 ? data.coinHoldings.length : 0}</p>
          </div>
        </div>
      </div>

      {/* Share Link Button */}
      <button
        onClick={handleShareLink}
        className="w-full bg-[#F7931A] hover:bg-[#E87D0D] text-white font-bold py-2 px-4 rounded transition-colors duration-200"
      >
        {copySuccess ? 'Copied!' : 'Share Link'}
      </button>

      {/* Coin Holdings */}
      <div>
        {data.coinHoldings && data.coinHoldings.length > 0 ? (
          <div className="space-y-3">
            <h2 className="text-2xl font-bold text-[#F7931A] mb-3">MEME</h2>
            {data.coinHoldings.map((holding) => (
              <Link
                key={holding.coinId}
                to={`/coin/${holding.coinId}`}
                className="bg-white rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 flex"
              >
                <div className="w-20 h-20 flex-shrink-0">
                  <img
                    src={holding.coin.iconUrl}
                    alt={holding.coin.name}
                    className="w-full h-full object-cover rounded-l-lg"
                  />
                </div>
                <div className="flex-grow p-4 flex items-center">
                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <div>
                        <span className="font-bold text-[#F7931A] text-lg mr-2">{holding.coin.name} </span>
                        <p className="text-sm text-orange-500">
                          {holding.coin.supply}
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="text-sm font-semibold text-[#F7931A]">{holding.coinAmount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (

          <div className="space-y-3">
            {data.toBuyCoins.map((coin) => (
              <Link
                key={coin.id}
                to={`/coin/${coin.id}`}
                className="bg-white rounded-lg shadow-lg overflow-hidden hover:bg-orange-100 transition-colors duration-200 flex"
              >
                <div className="w-20 h-20 flex-shrink-0">
                  <img
                    src={coin.iconUrl}
                    alt={coin.name}
                    className="w-full h-full object-cover rounded-l-lg"
                  />
                </div>
                <div className="flex-grow p-4 flex items-center">
                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <div>
                        <span className="text-[#F7931A] text-base mr-2">{coin.name} </span>
                        <p className="text-sm text-orange-500">
                          {coin.userId ? `Created by: @${coin.user.telegramUsername}` : coin.characterId ? `By ${coin.character.name}` : ''}
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="text-sm font-semibold text-[#F7931A]">
                          {coin.supply > 0
                            ? ((coin.supply / 1_000_000_000) * 100).toFixed(2)
                            : '0.00'}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>

        )}
      </div>
    </div>
  );
};

export default Profile;
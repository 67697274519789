import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { useUser } from '../UserContext';

const DepositDetail = () => {
  const [deposit, setDeposit] = useState(null);
  const [error, setError] = useState(null);
  const { token } = useUser();
  const { id } = useParams();

  useEffect(() => {

    if (!token) {
      return;
    }

    const fetchDeposit = async () => {
      try {
        const response = await axios.get(`https://api.mymeme.exchange/deposits/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setDeposit(response.data);
      } catch (err) {
        setError('Failed to fetch deposit details');
        console.error(err);
      }
    };

    fetchDeposit();
  }, [id]);

  if (error) return <div>Error: {error}</div>;
  if (!deposit) return <div>Loading...</div>;

  return (
    <div className="space-y-6 p-2 rounded-xl">

      <div className="flex justify-center">
        <QRCode value={deposit.publicKey} size={256} />
      </div>

      <div className="bg-white rounded-lg shadow-lg p-4">
        <div className="space-y-2">
          <div>
            <p className="text-sm text-[#F7931A]">Status</p>
            <p className="text-lg font-bold text-[#F7931A]">{deposit.status.charAt(0).toUpperCase() + deposit.status.slice(1)}</p>
          </div>
          <div>
            <p className="text-sm text-[#F7931A]">Address</p>
            <p className="text-lg font-bold text-[#F7931A] word-break-break-all">{deposit.publicKey}</p>
          </div>
          {deposit.status === 'consolidated' && (
            <>
              <div>
                <p className="text-sm text-[#F7931A]">$</p>
                <p className="text-lg font-bold text-[#F7931A] word-break-break-all">{(deposit.cent / 100).toFixed(2)}</p>
              </div>              
              <div>
                <p className="text-sm text-[#F7931A]">SOL</p>
                <p className="text-lg font-bold text-[#F7931A] word-break-break-all">{(deposit.lamport / 1_000_000_000).toFixed(2)}</p>
              </div>
              <div>
                <p className="text-sm text-[#F7931A]">Updated At</p>
                <p className="text-lg font-bold text-[#F7931A] word-break-break-all">{new Date(deposit.updatedAt).toLocaleString()}</p>
              </div>              
            </>
          )}
          <div>
            <p className="text-sm text-[#F7931A]">Created At</p>
            <p className="text-lg font-bold text-[#F7931A] word-break-break-all">{new Date(deposit.createdAt).toLocaleString()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositDetail;
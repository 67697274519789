import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [userInfo, setUserInfo] = useState({
        userId: null,
        telegramId: null,
        telegramUsername: null,        
        centBalance: 0,
        memeBalance: 0,

        tonWalletConnected: false,
        twitterConnected: false,
    });


    useEffect(() => {
        // Set up axios interceptor
        const interceptor = axios.interceptors.request.use(
            (config) => {
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        // Clean up interceptor on unmount
        return () => axios.interceptors.request.eject(interceptor);
    }, [token]);

    const updateUserInfo = useCallback((newUserData) => {
        setUserInfo(prevUser => ({ ...prevUser, ...newUserData }));
    }, []);



    const contextValue = {
        token,
        setToken,
        isLoading,
        setIsLoading,
        userInfo,
        updateUserInfo,
    };

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';

const Intro = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { setToken } = useUser();

  const baseAPI = 'https://api.mymeme.exchange';

  useEffect(() => {
    const initTelegramApp = async () => {
      try {
        const tg = window.Telegram.WebApp;
        tg.ready();
        const initData = tg.initData;
        const response = await axios.post(`${baseAPI}/token`, { initData });
        console.log('Data: ', response.data)
        setToken(response.data.token);        
        tg.expand();
        navigate(response.data.pageLink);
      } catch (err) {
        console.error('Error initializing Telegram app:', err);
        setError('Failed to initialize the app. Please try again.');
        navigate('/unauthorised');
      }
    };

    initTelegramApp();
  }, [navigate, baseAPI, setToken]);

  if (error) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="bg-red-600 text-white p-6 rounded-lg shadow-lg max-w-sm w-full">
          <h2 className="text-2xl font-bold mb-4">Error</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center">
      <div className="text-center">
        <div className="w-16 h-16 border-4 border-yellow-400 border-t-orange-500 rounded-full animate-spin mx-auto mb-4"></div>
        <p className="text-yellow-400 text-xl font-bold">Loading Meme Magic...</p>
      </div>
    </div>
  );
};

export default Intro;